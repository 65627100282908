import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useHistory, useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { toast } from "react-toastify";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";
import XLSX from "xlsx";
import styles from "../Mypage.module.scss";
import "./GroupUserList.css";
import AuthContext from "../../../context/Auth.context";
import Message from "../../../utilities/Message";
import Header from "../../components/header/Header";
import Navbar from "../Navbar";
import { Btn, BtnWrapper, Wrapper } from "../style";
// import * as Sentry from "@sentry/react";

const ColorTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  selected: { color: "black", backgroundColor: "#ffebd4 !important" },
}))(TableRow);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    marginLeft: "3px",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: "15.5px",
    letterSpacing: "-0.175px",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Noto Sans KR",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    "&::placeholder": {
      fontSize: 14,
    },
  },
}))(InputBase);

const GroupTestUserList = (props) => {
  // var process = require("../../../myProcess.json");

  const auth = useContext(AuthContext);
  const useStyles = makeStyles((theme) => ({
    root: {},
    form: {
      padding: "0px",
    },
    tab: {
      borderRaidus: "20px",
      fontWeight: "900",
      color: "gray",
      boxShadow: "5px 5px 10px #e7e7e7",
    },
    tabBtn: {
      width: "100%",
    },
    tF: {
      marginLeft: "0px !important",
    },
    indicator: {
      backgroundColor: "#007bff",
    },
  }));

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#a8a9a9",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
    },
  }))(TableCell);

  const classes = useStyles();
  const [userData, setUserData] = useState([]);
  const [searchedData, setSearched] = useState([]);
  const [examInfo, setExamInfo] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [search, setSearch] = useState({
    selected: "userName",
    title: "이름",
    content: "",
  });
  const [value, setValue] = useState("one");
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("uid");
  const userName = localStorage.getItem("userName");
  const [groupId, setGroupId] = useState();
  const [examActive, setExamAvtive] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const [selectedName, setSelectedName] = useState();
  const [selectedId, setSelectedId] = useState();
  const [selectedSubClassId, setSelectedSubClassId] = useState();
  const [examClass, setExamClass] = useState();
  const [groupCourseInfo, setGroupCourseInfo] = useState([]);
  const [targetScore, setTargetScore] = useState([]);
  const [firstAvg, setFirstAvg] = useState();
  const [secondAvg, setSecondAvg] = useState();
  const [selected, setSelected] = useState([]);

  const [tableData, setTableData] = useState(1);
  const selectExamSubjectChange = (e) => {
    setSelected(e.target.value.split(","));
  };

  const [examActiveStatus, setExamActiveStatus] = useState();

  useEffect(() => {
    selected[1] === "1"
      ? setExamActiveStatus(true)
      : setExamActiveStatus(false);
  }, [selected[0]]);

  const selectExamChange = (e) => {
    let eCategoryName = e.target.value.split(" 과목 ")[0];
    let eTmp = e.target.value.split(" 과목 ")[1];
    let eSubClasSId = eTmp.split(" 반")[0];
    setSelectedName(eCategoryName);
    examActive.filter((exam) => {
      if (exam.categoryName === eCategoryName) {
        setSelectedId(exam.classCategoryId);
        setSelectedSubClassId(eSubClasSId);
        return;
      }
    });
  };

  const tableToExcel = () => {
    // work_book 생성
    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.table_to_sheet(
      document.getElementById("groupTestUserListForPrint"),
      { raw: true }
    );

    // work_book에 work_sheet 추가
    XLSX.utils.book_append_sheet(wb, ws1, "Result");

    // work_sheet 컬럼(순서대로) 너비 설정
    ws1["!cols"].push(
      { width: 7 },
      { width: 15 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 }
    );

    XLSX.writeFile(wb, `역량평가_${groupCourseInfo.groupCourse}_report.xlsx`);
  };

  const secondToClock = (time) => {
    let hour = parseInt(time / 3600);
    let min = parseInt((time % 3600) / 60);
    let sec = time % 60;

    if (hour === 0) {
      return `${min}분 ${sec}초`;
    }
    if (hour === 0 && min === 0) {
      return `${sec}초`;
    } else {
      return `${hour}시간 ${min}분 ${sec}초`;
    }
  };

  const clickToAnalysis = (event) => {
    event.preventDefault();
    history.push({
      pathname: "/teacher/test/analysis",
      state: {
        groupId: groupId,
        classCategoryId: selectedId,
        categoryName: selectedName,
        groupCourseInfo: groupCourseInfo,
        subClassId: selectedSubClassId,
      },
    });
  };

  useState(() => {
    if (!auth.auth) return;
    const url = `${process.env.REACT_APP_IP}/teachers/exam/subject/${auth.groupId}`;
    axios
      .get(url, {
        headers: {
          AccessToken: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setExamAvtive(response.data);
        setExamClass(setToArray(response.data));
        setSelectedName(response.data[0].categoryName);
        setSelectedId(response.data[0].classCategoryId);
        setSelectedSubClassId(response.data[0].subClassId);
        setExamInfo(response.data[0]);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/");
          // Sentry.captureException(error);
        }
      });
  });

  function setToArray(data) {
    const tmpSet = new Set();
    for (let i = 0; i < data.length; i++) {
      let tmpStr = `${data[i].categoryName} 과목 ${data[i].subClassId} 반`;
      tmpSet.add(tmpStr);
    }
    return Array.from(tmpSet);
  }

  const fetchTestResult = () => {
    const url = `${process.env.REACT_APP_IP}/teachers/exam/examGroupResult/${auth.groupId}/${selectedId}/${selectedSubClassId}`;
    axios
      .get(url, {
        headers: {
          AccessToken: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let id = 0;
        let list = response.data.map((data) => {
          id++;
          return { id, ...data };
        });
        setUserData(list);
        setSearched(list);
        setGroupCourseInfo(response.data[0]);
        list.filter((data) => {
          if (data.firstExamGroupTargetScore > 0) {
            setTargetScore(data.firstExamGroupTargetScore);
            setFirstAvg(data.firstExamGroupScoreAvg);
            setSecondAvg(data.secondExamGroupScoreAvg);
            return;
          }
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            toast.error(Message.SERVICE_INVALID_TOKEN);
          } else if (error.response.status === 403) {
            toast.error(Message.ERROR_FORBIDDEN);
          } else if (error.response.status === 500) {
            toast.error(Message.ERROR_500);
          }
          // auth.onLogout();
          // history.push("/login");
          // Sentry.captureException(error);
        }
      });
  };

  useEffect(() => {
    if (typeof selectedSubClassId !== "undefined") {
      fetchTestResult();
    }
  }, [selectedSubClassId, selectedName]);

  const selectChange = (e) => {
    if (e.target.value === "userName") {
      setSearch({ ...search, selected: e.target.value, title: "이름" });
      setSearched(
        userData.filter((l) => {
          return l.userName.indexOf(search.content) !== -1;
        })
      );
    } else if (e.target.value === "userId") {
      setSearch({
        ...search,
        selected: e.target.value,
        title: "아이디",
      });
      setSearched(
        userData.filter((l) => {
          return l.userId.indexOf(search.content) !== -1;
        })
      );
    }
  };

  const handleChangeSearch = (e) => {
    if (search.selected === "userName") {
      setSearched(
        userData.filter((l) => {
          return l.userName.indexOf(e.target.value) !== -1;
        })
      );
    } else if (search.selected === "userId") {
      setSearched(
        userData.filter((l) => {
          return l.userId.indexOf(e.target.value) !== -1;
        })
      );
    }
    setSearch({ ...search, content: e.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToGroupReportDetail = (userId, event) => {
    event.preventDefault();
    history.push({
      pathname: "/groupReportDetail",
      state: {
        userId: userId,
        groupId: groupId,
      },
    });
  };

  const goToGroupTestResult = (
    userId,
    firstExamRank,
    secondExamRank,
    event
  ) => {
    event.preventDefault();
    firstExamRank || secondExamRank
      ? history.push({
          pathname: "/teacher/test/groupTestResult",
          state: {
            userId: userId,
            groupCourse: groupCourseInfo.groupCourse,
          },
        })
      : toast.error("시험 결과가 없습니다.");
  };

  const formatYAxis = (tickItem) => {
    return "";
  };

  const ref = useRef(null);
  const sidebar = "left";

  return (
    <>
      <Header
        data={{ title: groupCourseInfo.groupCourse, content: selectedName }}
      />
      <Navbar title={"시험 관리"} />
      <div className={styles.mypage} ref={ref}>
        <section
          className={[
            styles.wrapper,
            sidebar === "left"
              ? styles.with_sidebar + " " + styles.left
              : sidebar === "right"
              ? styles.with_sidebar + " " + styles.right
              : null,
          ].join(" ")}
        >
          <article className="mypage">
            <div className="mypageTable">
              <Container>
                <div className="row detailBox">
                  <div className="col-md-2 detailLeft secotext">교육명</div>
                  <div className="col-md-6 detailRight secotext">
                    {groupCourseInfo.groupCourse}
                  </div>
                  <div className="col-md-2 detailLeft secotext">차수</div>
                  <div className="col-md-2 detailRight secotext">
                    {groupCourseInfo.groupCourseCount}
                  </div>
                  {/* <div className="col-md-4 detailRight secotext">{list.postTitle}</div> */}
                </div>
                <div className="row detailBox">
                  <div className="col-md-2 detailLeft secotext">평가 과목</div>
                  <div className="col-md-4 detailRight secotext">
                    {selectedName}
                  </div>
                  <div className="col-md-2 detailLeft secotext">
                    교육 담당자
                  </div>
                  <div className="col-md-4 detailRight secotext">
                    {userName}
                  </div>
                </div>
                <div
                  className="row detailcontentBox"
                  style={{
                    marginBottom: "30px",
                    borderRadius: "0 0 5px 5px",
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <div className="col-md-2 detailLeft secotext">
                    교육 시작일
                  </div>
                  <div className="col-md-4 detailRight secotext">
                    {moment(groupCourseInfo.groupStartDate).format(
                      "YYYY-MM-DD"
                    )}
                  </div>
                  <div className="col-md-2 detailLeft secotext">
                    교육 종료일
                  </div>
                  <div className="col-md-4 detailRight secotext">
                    {moment(groupCourseInfo.groupDueDate).format("YYYY-MM-DD")}
                  </div>
                </div>
              </Container>

              <br />

              <h2 align="center">전체 교육생 점수 그래프</h2>
              <Container>
                <ResponsiveContainer width="100%" height={500}>
                  <ComposedChart
                    data={userData}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis
                      dataKey="userName"
                      fontSize="14px"
                      padding={{ left: 18, right: 18 }}
                      angle={-30}
                      dy={6}
                      height={48}
                    />
                    <YAxis
                      fontSize="14px"
                      width={30}
                      yAxisId="left"
                      label={{
                        value: "점수",
                        position: "top",
                        offset: "10",
                        fontSize: "12px",
                      }}
                      type="number"
                      domain={[0, 100]}
                      tickCount={11.5}
                    />
                    <YAxis
                      fontSize="14px"
                      width={32}
                      yAxisId="right"
                      orientation="right"
                      label={{
                        value: "상승률",
                        position: "top",
                        offset: "10",
                        fontSize: "12px",
                      }}
                    />
                    <Tooltip
                      contentStyle={{ fontSize: "14px" }}
                      formatter={function (value, name) {
                        return `${value === 0.3 ? 0 : value}`;
                      }}
                    />
                    <ReferenceLine
                      y={targetScore}
                      label={{
                        value: "목표점수",
                        fontSize: "13px",
                        position: "insideBottomLeft",
                      }}
                      stroke="#1DC218"
                      yAxisId="left"
                    />
                    <ReferenceLine
                      y={firstAvg}
                      label={{
                        value: firstAvg + "점: 사전평균",
                        fontSize: "13px",
                        position: "insideBottomLeft",
                      }}
                      stroke="#7C77EB"
                      yAxisId="left"
                    />
                    <ReferenceLine
                      y={secondAvg}
                      label={{
                        value: secondAvg + "점: 사후평균",
                        fontSize: "13px",
                        position: "insideBottomLeft",
                      }}
                      stroke="#FBA96F"
                      yAxisId="left"
                    />
                    <Bar
                      name="점수 상승률(%)"
                      yAxisId="right"
                      stackId="a"
                      type="monotone"
                      dataKey="scoreAscRate"
                      maxBarSize={25}
                      fill="#bdbcbc"
                      opacity={0.4}
                      label={({ x, y, value, fill }) => {
                        return (
                          <text
                            fill={fill}
                            x={x + 12}
                            y={y}
                            dy={-4}
                            textAnchor="middle"
                          >
                            {value === 0.3 ? null : value + "%"}
                          </text>
                        );
                      }}
                    />
                    <Bar
                      name="점수 하락률(%)"
                      yAxisId="right"
                      stackId="a"
                      type="monotone"
                      dataKey="scoreDescRate"
                      maxBarSize={25}
                      fill="#FCC396"
                      opacity={0.4}
                      label={({ x, y, value, fill }) => {
                        return (
                          <text
                            fill={fill}
                            x={x + 12}
                            y={y}
                            dy={-4}
                            textAnchor="middle"
                          >
                            {value === 0.3 ? null : value + "%"}
                          </text>
                        );
                      }}
                    />
                    <Line
                      name="사전 평가 점수(점)"
                      yAxisId="left"
                      type="monotone"
                      dataKey="firstExamScoreResult"
                      stroke="#1E14E0"
                      strokeWidth={2}
                    />
                    <Line
                      name="사후 평가 점수(점)"
                      yAxisId="left"
                      type="monotone"
                      dataKey="secondExamScoreResult"
                      stroke="#EE6505"
                      strokeWidth={2}
                    />
                    <Legend fontSize="14px" />
                  </ComposedChart>
                </ResponsiveContainer>
              </Container>

              {examClass && (
                <>
                  <Container style={{ padding: "0" }}>
                    <div style={{ marginBottom: "1vh", float: "left" }}>
                      <FormControl
                        className={classes.margin}
                        style={{
                          marginRight: "3px",
                          marginBottom: "1vh",
                          float: "left",
                        }}
                      >
                        <Select
                          native
                          id="select"
                          value={`${selectedName} 과목 ${selectedSubClassId} 반`}
                          onChange={selectExamChange}
                          input={<BootstrapInput />}
                        >
                          {examClass.map((exam, index) => {
                            return (
                              <>
                                <option className="secotext" value={exam}>
                                  {exam.split(" 과목 ")[0]}
                                </option>
                              </>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Container>
                </>
              )}
              <Container style={{ padding: "0" }}>
                <div style={{ fontSize: "2em", textAlign: "center" }}>
                  <div style={{ marginBottom: "1vh", float: "left" }}>
                    <button
                      onClick={(e) => clickToAnalysis(e)}
                      className="toAnalysisBtn"
                    >
                      <span className="secotext">
                        <i class="fas fa-chart-bar"></i>&nbsp;&nbsp;문항별 분석
                      </span>
                    </button>
                  </div>

                  <div style={{ marginBottom: "1vh", float: "right" }}>
                    <button
                      onClick={() => {
                        setTableData(1);
                        setSearched(
                          userData.sort((a, b) => {
                            if (a.userName < b.userName) return -1;
                            if (a.userName > b.userName) return 1;
                            if (a.userName === b.userName) return 0;
                          })
                        );
                      }}
                      className={`toSortBtn ${tableData === 1 && "activeBtn"}`}
                      value="이름별 정렬"
                    >
                      <span className="secotext">
                        <i class="fas fa-sort-amount-down-alt"></i>
                        &nbsp;&nbsp;이름별
                      </span>
                    </button>
                    <button
                      onClick={() => {
                        setTableData(2);
                        setSearched(
                          userData.sort(
                            (a, b) => a.firstExamRank - b.firstExamRank
                          )
                        );
                      }}
                      className={`toSortBtn ${tableData === 2 && "activeBtn"}`}
                      value="사전시험 순위별 정렬"
                    >
                      <span className="secotext">
                        <i class="fas fa-sort-amount-down-alt"></i>
                        &nbsp;&nbsp;사전 순위별
                      </span>
                    </button>
                    <button
                      onClick={() => {
                        setTableData(3);
                        setSearched(
                          userData.sort(
                            (a, b) => a.secondExamRank - b.secondExamRank
                          )
                        );
                      }}
                      className={`toSortBtn ${tableData === 3 && "activeBtn"}`}
                      value="사후시험 순위별 정렬"
                    >
                      <span className="secotext">
                        <i class="fas fa-sort-amount-down-alt"></i>
                        &nbsp;&nbsp;사후 순위별
                      </span>
                    </button>

                    <FormControl className={classes.margin}>
                      <Select
                        native
                        id="select"
                        value={search.selected}
                        onChange={selectChange}
                        input={<BootstrapInput />}
                      >
                        <option className="secotext" value="userName">
                          이름
                        </option>
                        <option className="secotext" value="userId">
                          아이디
                        </option>
                      </Select>
                    </FormControl>
                    <FormControl className={classes.margin}>
                      <BootstrapInput
                        id="demo-customized-textbox"
                        placeholder={`${search.title}(으)로 검색하기`}
                        name="content"
                        value={search.content}
                        onChange={handleChangeSearch}
                        style={{ width: "300px" }}
                      />
                    </FormControl>
                  </div>
                </div>
              </Container>
              <div style={{ fontSize: "2em", textAlign: "center" }}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="customized table"
                  >
                    <TableHead rowcount={userData.length}>
                      <TableRow>
                        <StyledTableCell
                          align="center"
                          style={{ width: "5%" }}
                          rowSpan="2"
                        >
                          <span className="secotext">No.</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: "13%" }}
                          rowSpan="2"
                        >
                          <span className="secotext">이름</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: "10%" }}
                          rowSpan="2"
                        >
                          <span className="secotext">분류</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: "12%" }}
                          rowSpan="2"
                        >
                          <span className="secotext">목표 점수</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: "12%" }}
                          rowSpan="2"
                        >
                          <span className="secotext">전체 평균</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: "12%" }}
                          rowSpan="2"
                        >
                          <span className="secotext">소요 시간</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: "12%" }}
                          rowSpan="2"
                        >
                          <span className="secotext">개인 점수</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: "27%" }}
                          colSpan="3"
                        >
                          <span className="secotext">난이도별 정답률</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: "10%" }}
                          rowSpan="2"
                        >
                          <span className="secotext">순위</span>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell style={{ width: "9%" }} align="center">
                          <span className="secotext">상</span>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "9%" }} align="center">
                          <span className="secotext">중</span>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "9%" }} align="center">
                          <span className="secotext">하</span>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchedData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <>
                              <ColorTableRow key={index}>
                                {row.retakeExamStartDate == null ? (
                                  <>
                                    <TableCell align="center" rowSpan="2">
                                      {row.id}
                                    </TableCell>
                                    <TableCell align="center" rowSpan="2">
                                      <Link
                                        to="#"
                                        onClick={(e) =>
                                          goToGroupTestResult(
                                            row.userId,
                                            row.firstExamRank,
                                            row.secondExamRank,
                                            e
                                          )
                                        }
                                        className="secotext"
                                      >
                                        {row.userName}
                                      </Link>
                                    </TableCell>
                                  </>
                                ) : (
                                  <>
                                    <TableCell align="center" rowSpan="3">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell align="center" rowSpan="3">
                                      <Link
                                        to="#"
                                        onClick={(e) =>
                                          goToGroupTestResult(
                                            row.userId,
                                            row.firstExamRank,
                                            row.secondExamRank,
                                            e
                                          )
                                        }
                                        className="secotext"
                                      >
                                        {row.userName}
                                      </Link>
                                    </TableCell>
                                  </>
                                )}
                                <TableCell align="center">
                                  <span className="secotext">사전</span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.firstExamGroupTargetScore <= 0
                                      ? "-"
                                      : row.firstExamGroupTargetScore}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.firstExamGroupScoreAvg <= 0
                                      ? "-"
                                      : row.firstExamGroupScoreAvg}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {secondToClock(row.firstExamTimeRequired)}
                                  </span>
                                </TableCell>
                                {row.firstExamScoreResult <= 0 ? (
                                  <TableCell style={{ textAlign: "center" }}>
                                    {" "}
                                    <span className="secotext"> -</span>
                                  </TableCell>
                                ) : row.firstExamGroupTargetScore <=
                                  row.firstExamScoreResult ? (
                                  <TableCell
                                    style={{
                                      textAlign: "center",
                                      color: "#FA7F42",
                                    }}
                                  >
                                    {" "}
                                    <span className="secotext">
                                      {" "}
                                      <strong>
                                        {row.firstExamScoreResult}{" "}
                                      </strong>{" "}
                                    </span>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    style={{
                                      textAlign: "center",
                                      color: "#2C95D2",
                                    }}
                                  >
                                    {" "}
                                    <span className="secotext">
                                      {" "}
                                      <strong>
                                        {row.firstExamScoreResult}
                                      </strong>{" "}
                                    </span>
                                  </TableCell>
                                )}
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.firstExamHighLevelScoreCount <= 0 &&
                                    row.firstExamHighLevelCount <= 0
                                      ? "-"
                                      : (
                                          (row.firstExamHighLevelScoreCount /
                                            row.firstExamHighLevelCount) *
                                          100
                                        ).toFixed(0) + "%"}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.firstExamMiddleLevelScoreCount <= 0 &&
                                    row.firstExamMiddleLevelCount <= 0
                                      ? "-"
                                      : (
                                          (row.firstExamMiddleLevelScoreCount /
                                            row.firstExamMiddleLevelCount) *
                                          100
                                        ).toFixed(0) + "%"}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.firstExamLowLevelScoreCount <= 0 &&
                                    row.firstExamLowLevelCount <= 0
                                      ? "-"
                                      : (
                                          (row.firstExamLowLevelScoreCount /
                                            row.firstExamLowLevelCount) *
                                          100
                                        ).toFixed(0) + "%"}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.firstExamRank <= 0
                                      ? "-"
                                      : row.firstExamRank}
                                  </span>
                                </TableCell>
                              </ColorTableRow>
                              <ColorTableRow
                                style={{ borderBottom: "2px solid #c4c4c4" }}
                              >
                                <TableCell align="center">
                                  <span className="secotext">사후</span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.secondExamGroupTargetScore <= 0
                                      ? "-"
                                      : row.secondExamGroupTargetScore}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.secondExamGroupScoreAvg <= 0
                                      ? "-"
                                      : row.secondExamGroupScoreAvg}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {secondToClock(row.secondExamTimeRequired)}
                                  </span>
                                </TableCell>
                                {row.secondExamScoreResult <= 0 ? (
                                  <TableCell style={{ textAlign: "center" }}>
                                    {" "}
                                    <span className="secotext"> - </span>
                                  </TableCell>
                                ) : row.secondExamGroupTargetScore <=
                                  row.secondExamScoreResult ? (
                                  <TableCell
                                    style={{
                                      textAlign: "center",
                                      color: "#FA7F42",
                                    }}
                                  >
                                    {" "}
                                    <span className="secotext">
                                      {" "}
                                      <strong>
                                        {row.secondExamScoreResult}
                                      </strong>{" "}
                                    </span>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    style={{
                                      textAlign: "center",
                                      color: "#2C95D2",
                                    }}
                                  >
                                    {" "}
                                    <span className="secotext">
                                      {" "}
                                      <strong>
                                        {row.secondExamScoreResult}
                                      </strong>{" "}
                                    </span>
                                  </TableCell>
                                )}
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.secondExamHighLevelScoreCount <= 0 &&
                                    row.secondExamHighLevelCount <= 0
                                      ? "-"
                                      : (
                                          (row.secondExamHighLevelScoreCount /
                                            row.secondExamHighLevelCount) *
                                          100
                                        ).toFixed(0) + "%"}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.secondExamMiddleLevelScoreCount <= 0 &&
                                    row.secondExamMiddleLevelCount <= 0
                                      ? "-"
                                      : (
                                          (row.secondExamMiddleLevelScoreCount /
                                            row.secondExamMiddleLevelCount) *
                                          100
                                        ).toFixed(0) + "%"}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.secondExamLowLevelScoreCount <= 0 &&
                                    row.secondExamLowLevelCount <= 0
                                      ? "-"
                                      : (
                                          (row.secondExamLowLevelScoreCount /
                                            row.secondExamLowLevelCount) *
                                          100
                                        ).toFixed(0) + "%"}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="secotext">
                                    {row.secondExamRank <= 0
                                      ? "-"
                                      : row.secondExamRank}
                                  </span>
                                </TableCell>
                              </ColorTableRow>
                              {row.retakeExamStartDate != null ? (
                                <ColorTableRow
                                  style={{
                                    borderBottom: "2px solid #c4c4c4",
                                  }}
                                >
                                  <TableCell align="center">
                                    <span
                                      className="secotext"
                                      style={{ color: "red" }}
                                    >
                                      재평가
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="secotext">
                                      {row.retakeExamGroupTargetScore <= 0
                                        ? "-"
                                        : row.retakeExamGroupTargetScore}
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="secotext">
                                      {row.retakeExamGroupScoreAvg <= 0
                                        ? "-"
                                        : row.retakeExamGroupScoreAvg}
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="secotext">
                                      {secondToClock(
                                        row.retakeExamTimeRequired
                                      )}
                                    </span>
                                  </TableCell>
                                  {row.retakeExamScoreResult <= 0 ? (
                                    <TableCell style={{ textAlign: "center" }}>
                                      {" "}
                                      <span className="secotext"> - </span>
                                    </TableCell>
                                  ) : row.retakeExamGroupTargetScore <=
                                    row.retakeExamScoreResult ? (
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        color: "#FA7F42",
                                      }}
                                    >
                                      {" "}
                                      <span className="secotext">
                                        {" "}
                                        <strong>
                                          {row.retakeExamScoreResult}
                                        </strong>{" "}
                                      </span>
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        color: "#2C95D2",
                                      }}
                                    >
                                      {" "}
                                      <span className="secotext">
                                        {" "}
                                        <strong>
                                          {row.retakeExamScoreResult}
                                        </strong>{" "}
                                      </span>
                                    </TableCell>
                                  )}
                                  <TableCell align="center">
                                    <span className="secotext">
                                      {row.retakeExamHighLevelScoreCount <= 0 &&
                                      row.retakeExamHighLevelCount <= 0
                                        ? "-"
                                        : (
                                            (row.retakeExamHighLevelScoreCount /
                                              row.retakeExamHighLevelCount) *
                                            100
                                          ).toFixed(0) + "%"}
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="secotext">
                                      {row.retakeExamMiddleLevelScoreCount <=
                                        0 && row.retakeExamMiddleLevelCount <= 0
                                        ? "-"
                                        : (
                                            (row.retakeExamMiddleLevelScoreCount /
                                              row.retakeExamMiddleLevelCount) *
                                            100
                                          ).toFixed(0) + "%"}
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="secotext">
                                      {row.retakeExamLowLevelScoreCount <= 0 &&
                                      row.retakeExamLowLevelCount <= 0
                                        ? "-"
                                        : (
                                            (row.retakeExamLowLevelScoreCount /
                                              row.retakeExamLowLevelCount) *
                                            100
                                          ).toFixed(0) + "%"}
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="secotext">
                                      {row.retakeExamRank <= 0
                                        ? "-"
                                        : row.retakeExamRank}
                                    </span>
                                  </TableCell>
                                </ColorTableRow>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[15, 25]}
                  component="div"
                  count={searchedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </article>
        </section>
      </div>
      <TableContainer
        component={Paper}
        id="groupTestUserListForPrint"
        style={{ display: "none" }}
      >
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
        >
          <TableHead rowcount={userData.length}>
            <TableRow>
              <StyledTableCell
                align="center"
                style={{ width: "5%" }}
                rowSpan="2"
              >
                <span className="secotext">No.</span>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ width: "13%" }}
                rowSpan="2"
              >
                <span className="secotext">이름</span>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ width: "10%" }}
                rowSpan="2"
              >
                <span className="secotext">분류</span>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ width: "12%" }}
                rowSpan="2"
              >
                <span className="secotext">목표 점수</span>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ width: "12%" }}
                rowSpan="2"
              >
                <span className="secotext">전체 평균</span>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ width: "12%" }}
                rowSpan="2"
              >
                <span className="secotext">개인 점수</span>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ width: "27%" }}
                colSpan="3"
              >
                <span className="secotext">난이도별 정답률</span>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ width: "10%" }}
                rowSpan="2"
              >
                <span className="secotext">순위</span>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ width: "9%" }} align="center">
                <span className="secotext">상</span>
              </StyledTableCell>
              <StyledTableCell style={{ width: "9%" }} align="center">
                <span className="secotext">중</span>
              </StyledTableCell>
              <StyledTableCell style={{ width: "9%" }} align="center">
                <span className="secotext">하</span>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedData.map((row, index) => {
              return (
                <>
                  <ColorTableRow key={index}>
                    <TableCell align="center" rowSpan="2">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center" rowSpan="2">
                      <Link
                        to="#"
                        onClick={(e) => goToGroupTestResult(row.userId, e)}
                        className="secotext"
                      >
                        {row.userName}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">사전</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.firstExamGroupTargetScore <= 0
                          ? "-"
                          : row.firstExamGroupTargetScore}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.firstExamGroupScoreAvg <= 0
                          ? "-"
                          : row.firstExamGroupScoreAvg}
                      </span>
                    </TableCell>
                    {row.firstExamScoreResult <= 0 ? (
                      <TableCell style={{ textAlign: "center" }}>
                        {" "}
                        <span className="secotext"> -</span>
                      </TableCell>
                    ) : row.firstExamGroupTargetScore <=
                      row.firstExamScoreResult ? (
                      <TableCell
                        style={{ textAlign: "center", color: "#FA7F42" }}
                      >
                        {" "}
                        <span className="secotext">
                          {" "}
                          <strong>{row.firstExamScoreResult} </strong>{" "}
                        </span>
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{ textAlign: "center", color: "#2C95D2" }}
                      >
                        {" "}
                        <span className="secotext">
                          {" "}
                          <strong>{row.firstExamScoreResult}</strong>{" "}
                        </span>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <span className="secotext">
                        {row.firstExamHighLevelScoreCount <= 0 &&
                        row.firstExamHighLevelCount <= 0
                          ? "-"
                          : (
                              (row.firstExamHighLevelScoreCount /
                                row.firstExamHighLevelCount) *
                              100
                            ).toFixed(0) + "%"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.firstExamMiddleLevelScoreCount <= 0 &&
                        row.firstExamMiddleLevelCount <= 0
                          ? "-"
                          : (
                              (row.firstExamMiddleLevelScoreCount /
                                row.firstExamMiddleLevelCount) *
                              100
                            ).toFixed(0) + "%"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.firstExamLowLevelScoreCount <= 0 &&
                        row.firstExamLowLevelCount <= 0
                          ? "-"
                          : (
                              (row.firstExamLowLevelScoreCount /
                                row.firstExamLowLevelCount) *
                              100
                            ).toFixed(0) + "%"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.firstExamRank <= 0 ? "-" : row.firstExamRank}
                      </span>
                    </TableCell>
                  </ColorTableRow>
                  <ColorTableRow style={{ borderBottom: "2px solid #c4c4c4" }}>
                    <TableCell align="center">
                      <span className="secotext">사후</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.secondExamGroupTargetScore <= 0
                          ? "-"
                          : row.secondExamGroupTargetScore}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.secondExamGroupScoreAvg <= 0
                          ? "-"
                          : row.secondExamGroupScoreAvg}
                      </span>
                    </TableCell>
                    {row.secondExamScoreResult <= 0 ? (
                      <TableCell style={{ textAlign: "center" }}>
                        {" "}
                        <span className="secotext"> - </span>
                      </TableCell>
                    ) : row.secondExamGroupTargetScore <=
                      row.secondExamScoreResult ? (
                      <TableCell
                        style={{ textAlign: "center", color: "#FA7F42" }}
                      >
                        {" "}
                        <span className="secotext">
                          {" "}
                          <strong>{row.secondExamScoreResult}</strong>{" "}
                        </span>
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{ textAlign: "center", color: "#2C95D2" }}
                      >
                        {" "}
                        <span className="secotext">
                          {" "}
                          <strong>{row.secondExamScoreResult}</strong>{" "}
                        </span>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <span className="secotext">
                        {row.secondExamHighLevelScoreCount <= 0 &&
                        row.secondExamHighLevelCount <= 0
                          ? "-"
                          : (
                              (row.secondExamHighLevelScoreCount /
                                row.secondExamHighLevelCount) *
                              100
                            ).toFixed(0) + "%"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.secondExamMiddleLevelScoreCount <= 0 &&
                        row.secondExamMiddleLevelCount <= 0
                          ? "-"
                          : (
                              (row.secondExamMiddleLevelScoreCount /
                                row.secondExamMiddleLevelCount) *
                              100
                            ).toFixed(0) + "%"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.secondExamLowLevelScoreCount <= 0 &&
                        row.secondExamLowLevelCount <= 0
                          ? "-"
                          : (
                              (row.secondExamLowLevelScoreCount /
                                row.secondExamLowLevelCount) *
                              100
                            ).toFixed(0) + "%"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="secotext">
                        {row.secondExamRank <= 0 ? "-" : row.secondExamRank}
                      </span>
                    </TableCell>
                  </ColorTableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Wrapper>
        <BtnWrapper>
          <Link to={`/teacher/test`}>
            <Btn color="rgba(0, 0, 0, 0.3)">돌아가기</Btn>
          </Link>
          <Btn color="#21a325" onClick={() => tableToExcel()}>
            <i className="far fa-file-excel"></i> Excel 다운로드
          </Btn>
        </BtnWrapper>
      </Wrapper>
    </>
  );
};

export default withRouter(GroupTestUserList);
